import { useFormikContext } from "formik";

import {
  OfferRedemptionMechanic,
  PromotionType
} from "../../../../../clients/services/sams/promotion/types";
import { SunProduct } from "../../../../../clients/services/sams/types";
import { FormDataInterface } from "../../../types";

export function useConditions(values: FormDataInterface) {
  const showCodeCollectStartDate =
    values.promotionType !== PromotionType.NONCODE &&
    values.promotionType !== PromotionType.DONATION;

  const showRedemptionStartDateTime =
    values.promotionType === PromotionType.REGULAR ||
    values.promotionType === PromotionType.NONCODE ||
    values.promotionType === PromotionType.DONATION;

  const isBookingCodeMechanic =
    values.redemptionMechanic === OfferRedemptionMechanic.BOOKING_CODE;
  const isRegularPromotion = values.promotionType === PromotionType.REGULAR;
  const isNonCodePromotionWithClub =
    values.promotionType === PromotionType.NONCODE &&
    values.sunProduct === SunProduct.SUN_CLUB;

  const showBookingCodeEndDate =
    isBookingCodeMechanic && (isRegularPromotion || isNonCodePromotionWithClub);

  const showRedemptionButtonText =
    values.promotionType === PromotionType.REGULAR ||
    values.promotionType === PromotionType.NONCODE ||
    values.promotionType === PromotionType.WALLET;

  const showResendButtonText =
    values.redemptionMechanic === OfferRedemptionMechanic.INTERNAL &&
    values.promotionType !== PromotionType.NONCODE &&
    values.sunProduct === SunProduct.SUN_SAVERS;

  const showRedemptionMechanic =
    values.promotionType !== PromotionType.DONATION &&
    values.sunProduct !== SunProduct.SUN_MOBILE &&
    !(
      (values.sunProduct === SunProduct.SUN_SAVERS ||
        values.sunProduct === SunProduct.MEMBERS_ENCLOSURE) &&
      values.promotionType === PromotionType.NONCODE
    );

  const showMaximumRedemptions =
    values.promotionType !== PromotionType.COMPETITION &&
    values.promotionType !== PromotionType.REGULAR;

  const showFeeOnRedemption =
    values.promotionType === PromotionType.WALLET ||
    values.promotionType === PromotionType.REGULAR;

  const showTransactionHistoryLabel =
    values.promotionType === PromotionType.WALLET ||
    values.promotionType === PromotionType.DONATION ||
    values.promotionType === PromotionType.REGULAR;

  const shouldIncludeMechanic = (
    mechanic: OfferRedemptionMechanic
  ): boolean => {
    const formik: any = useFormikContext();
    const { promotionType } = formik.values;

    if (promotionType === PromotionType.REGULAR) {
      return true;
    }

    if (isNonCodePromotionWithClub) {
      return (
        mechanic !== OfferRedemptionMechanic.PRODUCT &&
        mechanic !== OfferRedemptionMechanic.INTERNAL
      );
    }

    return mechanic !== OfferRedemptionMechanic.BOOKING_CODE;
  };

  return {
    showCodeCollectStartDate,
    showRedemptionStartDateTime,
    showBookingCodeEndDate,
    showRedemptionButtonText,
    showResendButtonText,
    showRedemptionMechanic,
    showMaximumRedemptions,
    showFeeOnRedemption,
    showTransactionHistoryLabel,
    isNonCodePromotionWithClub,
    shouldIncludeMechanic
  };
}
